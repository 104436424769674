import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/layouts/blogPost.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`2020 was a breakout year for the Uniswap protocol. As it comes to a close, we wanted to review some key accomplishments from the past 12 months and share some insight into our roadmap for the coming year.`}</p>
    <h1 {...{
      "id": "2020-review",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#2020-review",
        "aria-label": "2020 review permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2020 Review:`}</h1>
    <h3 {...{
      "id": "v2-launch-introduces-new-primitives-and-efficiency-gains",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#v2-launch-introduces-new-primitives-and-efficiency-gains",
        "aria-label": "v2 launch introduces new primitives and efficiency gains permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`V2 launch introduces new primitives and efficiency gains:`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://uniswap.org/blog/uniswap-v2/"
          }}>{`Uniswap v2`}</a>{` launched in May, bringing support for arbitrary ERC20/ERC20 pairs, robust time weighted average price oracles, flash swaps, and significant architecture improvements.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Flash swaps have generated $4.8bn volume since May, producing $14.4m in liquidity provider fees.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Uniswap’s TWAP implementation continues to see adoption as key oracle infrastructure, counting `}<a parentName="p" {...{
            "href": "https://debank.com/ranking/oracle?chart_date=1Y"
          }}>{`integrations`}</a>{` across over 50 protocols including Compound, Augur v2, and Empty Set Dollar — second only to Chainlink.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`While ETH remains the dominant pair numeraire, Uniswap v2 supports over 4,400 non-ETH pairs, including 7 of the 25 most liquid pairs at the time of this post.`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "volumes-surge-to-new-heights",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#volumes-surge-to-new-heights",
        "aria-label": "volumes surge to new heights permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Volumes surge to new heights:`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/461e33f4771ff56466cfbb83db3223df/903c8/volume.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAJABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAgABBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAHvDSoTlf/EABkQAAIDAQAAAAAAAAAAAAAAAAABAhASIf/aAAgBAQABBQIk+aY7/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/AY2P/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGRAAAgMBAAAAAAAAAAAAAAAAABABEWFB/9oACAEBAAE/ISjROSdX/9oADAMBAAIAAwAAABDj7//EABcRAAMBAAAAAAAAAAAAAAAAAAABEWH/2gAIAQMBAT8QRWmB/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGRABAQADAQAAAAAAAAAAAAAAAQARITFR/9oACAEBAAE/EADceAduYlomGvLqYcv/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "volume",
            "title": "volume",
            "src": "/static/461e33f4771ff56466cfbb83db3223df/e5166/volume.jpg",
            "srcSet": ["/static/461e33f4771ff56466cfbb83db3223df/f93b5/volume.jpg 300w", "/static/461e33f4771ff56466cfbb83db3223df/b4294/volume.jpg 600w", "/static/461e33f4771ff56466cfbb83db3223df/e5166/volume.jpg 1200w", "/static/461e33f4771ff56466cfbb83db3223df/d9c39/volume.jpg 1800w", "/static/461e33f4771ff56466cfbb83db3223df/903c8/volume.jpg 2340w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The Uniswap protocol supported more than `}<strong parentName="p">{`$`}{`58bn`}</strong>{` volume over the course of 2020, up `}<strong parentName="p">{`15,000%`}</strong>{` from `}{`$`}{`390m in 2019.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`In September, the protocol briefly surpassed Coinbase on weekly volumes. Today, Uniswap is the 5th largest venue for crypto spot trading in the world.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The protocol has averaged over 54% volume market share among trading protocols since January.`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "uniswaps-liquidity-rivals-that-of-centralized-counterparts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#uniswaps-liquidity-rivals-that-of-centralized-counterparts",
        "aria-label": "uniswaps liquidity rivals that of centralized counterparts permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Uniswap’s liquidity rivals that of centralized counterparts:`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c2e017f37d2f52ae94bd89060f4154b3/903c8/liquidity.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAJABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAADBf/EABYBAQEBAAAAAAAAAAAAAAAAAAMAAf/aAAwDAQACEAMQAAAB7xUYSBlWv//EABoQAAICAwAAAAAAAAAAAAAAAAACAxIBBBD/2gAIAQEAAQUCJ5aKmw1cjc//xAAZEQACAwEAAAAAAAAAAAAAAAAAAQIRIVL/2gAIAQMBAT8BhV6Nw5P/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAZEAABBQAAAAAAAAAAAAAAAAAAAREgIjH/2gAIAQEABj8CK6o0P//EABoQAQEAAgMAAAAAAAAAAAAAAAEAEEERMdH/2gAIAQEAAT8hk4vFDCBTbvDtF//aAAwDAQACAAMAAAAQC/8A/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARsf/aAAgBAwEBPxAgo2U71f/EABcRAQADAAAAAAAAAAAAAAAAAAABIVH/2gAIAQIBAT8QhWP/xAAbEAEAAgIDAAAAAAAAAAAAAAABACERQTFRsf/aAAgBAQABPxCi4dAcmF07lPnV1Y5M8JtP/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "liquidity",
            "title": "liquidity",
            "src": "/static/c2e017f37d2f52ae94bd89060f4154b3/e5166/liquidity.jpg",
            "srcSet": ["/static/c2e017f37d2f52ae94bd89060f4154b3/f93b5/liquidity.jpg 300w", "/static/c2e017f37d2f52ae94bd89060f4154b3/b4294/liquidity.jpg 600w", "/static/c2e017f37d2f52ae94bd89060f4154b3/e5166/liquidity.jpg 1200w", "/static/c2e017f37d2f52ae94bd89060f4154b3/d9c39/liquidity.jpg 1800w", "/static/c2e017f37d2f52ae94bd89060f4154b3/903c8/liquidity.jpg 2340w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The Uniswap protocol currently commands over $2bn in liquidity, up 17,000% from $12m at the start of the year.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Over 68,000 unique addresses are currently providing liquidity across 27,000 unique trading pairs.`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "users-gravitate-towards-decentralized-trading",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#users-gravitate-towards-decentralized-trading",
        "aria-label": "users gravitate towards decentralized trading permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Users gravitate towards decentralized trading:`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d4b0b10d2ba93970871b64c6e5bcbf24/903c8/addresses.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAJABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBBf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAAB7sDWKL//xAAZEAACAwEAAAAAAAAAAAAAAAAAAQIQEiH/2gAIAQEAAQUCNdUmOkf/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPwEn/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAHBAAAgICAwAAAAAAAAAAAAAAAAEhMRFBYbHx/9oACAEBAAE/IXhWN4JR6MS+Oixsof/aAAwDAQACAAMAAAAQrC//xAAXEQEBAQEAAAAAAAAAAAAAAAABABFR/9oACAEDAQE/EABrKcv/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAcEAEAAgMBAQEAAAAAAAAAAAABABEhMbHBYYH/2gAIAQEAAT8QRWqPrFAWQZ3sPIS0ZFQasPVm/wDfIdes4HCf/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "addresses",
            "title": "addresses",
            "src": "/static/d4b0b10d2ba93970871b64c6e5bcbf24/e5166/addresses.jpg",
            "srcSet": ["/static/d4b0b10d2ba93970871b64c6e5bcbf24/f93b5/addresses.jpg 300w", "/static/d4b0b10d2ba93970871b64c6e5bcbf24/b4294/addresses.jpg 600w", "/static/d4b0b10d2ba93970871b64c6e5bcbf24/e5166/addresses.jpg 1200w", "/static/d4b0b10d2ba93970871b64c6e5bcbf24/d9c39/addresses.jpg 1800w", "/static/d4b0b10d2ba93970871b64c6e5bcbf24/903c8/addresses.jpg 2340w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The protocol’s MAU figures have grown month-on-month since the launch of v2, with December surpassing 300k unique addresses.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Uniswap trades regularly account for 25% of Ethereum network transactions.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Meanwhile, the protocol’s median trade size in 2020 was just `}{`$`}{`634.`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "uniswap-as-neutral-robust-exchange-infrastructure",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#uniswap-as-neutral-robust-exchange-infrastructure",
        "aria-label": "uniswap as neutral robust exchange infrastructure permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Uniswap as neutral, robust exchange infrastructure:`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/003f01224d260cd180a3842f576bd361/903c8/integrations.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAJABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMCBf/EABcBAAMBAAAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAe2yLiqen//EABkQAQADAQEAAAAAAAAAAAAAAAEAAzECQf/aAAgBAQABBQIY7ZZ0LnrP/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARMf/aAAgBAwEBPwGEzl//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAACAQUAAAAAAAAAAAAAAAAAEAIhIjFxgf/aAAgBAQAGPwKpHZbhdX//xAAdEAACAQQDAAAAAAAAAAAAAAAAARExQXHwIVGx/9oACAEBAAE/IVtWPn0fZ0KMlJTQqYYtMFmD/9oADAMBAAIAAwAAABC7D//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEWH/2gAIAQMBAT8QA3TscCL/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPxBX/8QAHhAAAgIABwAAAAAAAAAAAAAAAREAMSFBUWFxsfD/2gAIAQEAAT8QbCDMDyCPqPUaJo7oByQQJZzwdZbCvHP/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "integrations",
            "title": "integrations",
            "src": "/static/003f01224d260cd180a3842f576bd361/e5166/integrations.jpg",
            "srcSet": ["/static/003f01224d260cd180a3842f576bd361/f93b5/integrations.jpg 300w", "/static/003f01224d260cd180a3842f576bd361/b4294/integrations.jpg 600w", "/static/003f01224d260cd180a3842f576bd361/e5166/integrations.jpg 1200w", "/static/003f01224d260cd180a3842f576bd361/d9c39/integrations.jpg 1800w", "/static/003f01224d260cd180a3842f576bd361/903c8/integrations.jpg 2340w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Uniswap now counts over 200 integrations across protocols, data analytics services, advanced interfaces, wallets, and more.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Highlights include Uniswap LP shares serving as collateral in MakerDAO; Uniswap flash loans being used for execution in Aave v2 collateral swaps, and Fireblock’s integration of Uniswap into its institutional-focused DeFi API.`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "christmas-comes-early-with-uni-token",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#christmas-comes-early-with-uni-token",
        "aria-label": "christmas comes early with uni token permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Christmas comes early with UNI token:`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`As part of the launch of Uniswap’s governance token, 150 million UNI — accounting for 15% of supply and currently worth approximately `}{`$`}{`630 million — was distributed to historical users and liquidity providers.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`An additional 20 million UNI were distributed to liquidity providers between September 16 and November 16.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Over 110,000 addresses currently hold UNI, making UNI one of the most widely distributed tokens on Ethereum.`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "uniswaps-governance-brings-the-protocol-closer-to-self-sustainability",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#uniswaps-governance-brings-the-protocol-closer-to-self-sustainability",
        "aria-label": "uniswaps governance brings the protocol closer to self sustainability permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Uniswap’s governance brings the protocol closer to self-sustainability:`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Uniswap governance counts roughly 5,000 unique delegates and 6,000 unique delegators, including active participation from many of the crypto community’s leading ambassadors.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The Uniswap `}<a parentName="p" {...{
            "href": "https://etherscan.io/address/0x1a9c8182c09f50c8318d769245bea52c32be35bc"
          }}>{`community treasury`}</a>{` currently holds over 47m UNI, which can be distributed through votes by token holders on a discretionary basis.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The first successful governance proposal `}<a parentName="p" {...{
            "href": "https://etherscan.io/tx/0x1348b107d951b7ceeb7d934f463d5f862464ff93988e73f3d8052f358fa2411c"
          }}>{`was executed`}</a>{` on December 27th, establishing a `}<a parentName="p" {...{
            "href": "https://gov.uniswap.org/t/rfc-uniswap-grants-program-v0-1/9081/32"
          }}>{`grants program`}</a>{` to invest in the ecosystem’s future.`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "token-lists-improves-erc20-discoverability",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#token-lists-improves-erc20-discoverability",
        "aria-label": "token lists improves erc20 discoverability permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Token Lists improves ERC20 discoverability:`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`In August, the Uniswap team launched `}<a parentName="p" {...{
            "href": "http://tokenlists.org/"
          }}>{`Token Lists`}</a>{`, a community standard for creating lists of ERC20 tokens.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://uniswap.org/blog/token-lists/"
          }}>{`Token Lists`}</a>{` improve discoverability and trust in ERC20s in a manner that is inclusive, transparent, and decentralized.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Token Lists has emerged as the industry standard for off-chain token discovery and counts support from industry-leading firms, including Coingecko, Coinmarketcap Messari, Set Protocol, Kleros, and Wrapped.com, and has been integrated into projects including Matcha.`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "sybil-makes-delegating-easy",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#sybil-makes-delegating-easy",
        "aria-label": "sybil makes delegating easy permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Sybil makes delegating easy:`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`In December, the Uniswap team launched `}<a parentName="p" {...{
            "href": "http://sybil.org/"
          }}>{`Sybil`}</a>{`, a cross-platform tool for improving the discoverability of governance delegates`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://uniswap.org/blog/sybil/"
          }}>{`Sybil`}</a>{` uses third party authentication platforms (Twitter, GitHub etc.) and digital signatures to map Ethereum addresses to digital identities.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Sybil-verified identities work cross-platform: Sybil supports Compound governance out of the box.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "2021-roadmap",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#2021-roadmap",
        "aria-label": "2021 roadmap permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2021 Roadmap:`}</h2>
    <h3 {...{
      "id": "improve-automated-market-making",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#improve-automated-market-making",
        "aria-label": "improve automated market making permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Improve automated market making:`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The Uniswap team continues to research and develop market leading AMM technology, making considerable progress on v3.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`V3’s design is driven by a desire to drastically improve the AMM experience for both swappers and LPs, increasing capital efficiency and flexibility while introducing superior execution.`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "explore-scaling-solutions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#explore-scaling-solutions",
        "aria-label": "explore scaling solutions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Explore scaling solutions:`}</h3>
    <ul>
      <li parentName="ul">{`Scaling solutions present a path to lower latency trading, faster settlement, and cheaper transaction costs.`}</li>
    </ul>
    <h3 {...{
      "id": "emphasis-on-governance",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#emphasis-on-governance",
        "aria-label": "emphasis on governance permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Emphasis on governance:`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Uniswap’s long term success is closely tied to its ability to attract the best available community talent and to engage in effective long-term, decentralized decision-making.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Expansion of governance requires both improved tooling (see Sybil) and focused initiatives like governance’s Uniswap Grants Program.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`As a result of the active participation in governance by thousands of delegates, the Uniswap core team members plan to participate more directly in governance in their personal capacity over the course of 2021: this includes publicly advocating for or against proposals.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Additionally, the core team may delegate votes to others or themselves, although they will vote with no more than 10% of their own UNI. Governance efforts will continue to be community-led.`}</p>
      </li>
    </ul>
    <p>{`We’re eternally grateful to the Ethereum community for helping us achieve so much over the past 12 months and are enormously excited for the year to come.`}</p>
    <p>{`Happy holidays to all, and see you in the new year!`}</p>
    <p>{`Uniswap Team
🦄`}</p>
    <hr></hr>
    <p><em parentName="p">{`To learn more and get involved in the Uniswap ecosystem, join the project’s`}</em>{` `}<a parentName="p" {...{
        "href": "https://discord.com/invite/FCfyBSbCU5"
      }}><em parentName="a">{`Discord`}</em></a>{` `}<em parentName="p">{`channel, participate in Uniswap’s governance discussions on the`}</em>{` `}<a parentName="p" {...{
        "href": "http://gov.uniswap.org/"
      }}><em parentName="a">{`Uniswap forum`}</em></a><em parentName="p">{`, and register as a delegate via`}</em>{` `}<a parentName="p" {...{
        "href": "http://sybil.org/"
      }}><em parentName="a">{`Sybil`}</em></a><em parentName="p">{`.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      